import type { Condition, ControlRules } from '@repo/api-gw-sdk';

import type { Step } from '@/components/wizard/Step';

import { ControlConditionEditor } from './ControlConditionEditor';
import { ControlNameSelection } from './ControlNameSelection';

export interface ControlEditState {
  id: string;
  name: string;
  condition?: Condition;
  rules: ControlRules;
}

export const ControlCreationFlow: Step<ControlEditState> = {
  name: 'Name Selection',
  Component: ControlNameSelection,
  next: [
    {
      name: 'Condition',
      Component: ControlConditionEditor,
    },
  ],
};
