import type {
  NotificationPolicy,
  NotificationPolicyTypeEnum,
} from '@repo/api-gw-sdk';

import { NotificationPolicyCredentials } from './notificationPolicyCredentials';
import { NotificationPolicyNameSelection } from './notificationPolicyNameSelection';
import { NotificationPolicyNotifications } from './notificationPolicyNotifications';

import type { Step } from '../wizard/Step';

export type UpsertNotificationPolicyState = NotificationPolicy & {
  type: NotificationPolicyTypeEnum | undefined;
};

export const upsertNotificationPolicyFlow: Step<UpsertNotificationPolicyState> =
  {
    name: 'Name Selection',
    Component: NotificationPolicyNameSelection,
    next: [
      {
        name: 'Credentials',
        Component: NotificationPolicyCredentials,
        next: [
          {
            name: 'Notifications',
            Component: NotificationPolicyNotifications,
          },
        ],
      },
    ],
  };
