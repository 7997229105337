import { NotificationType } from '@repo/api-gw-sdk';

const notificationTypes: Record<NotificationType, { title: string }> = {
  [NotificationType.NotificationTypeUnknown]: {
    title: 'Unknown',
  },
  [NotificationType.BackupJobFailed]: {
    title: 'Backup Job Failed',
  },
  [NotificationType.RestoreJobFailed]: {
    title: 'Restore Job Failed',
  },
  [NotificationType.RestoreJobSucceeded]: {
    title: 'Restore Job Succeeded',
  },
};

export const NotificationTypes = Object.fromEntries(
  Object.entries(notificationTypes)
);
