import { Button, OutlinedInput, Stack, Typography } from '@mui/material';
import { useCallback } from 'react';

import type { StepProps } from '@/components/wizard/StepProps';

import type { ControlEditState } from './ControlCreationFlow';

export const ControlNameSelection = (props: StepProps<ControlEditState>) => {
  const next = () => {
    props.setNextStep(props.currentStep.next?.[0]);
  };

  const validateName = useCallback((name: string): boolean => {
    return name?.length >= 4;
  }, []);

  return (
    <Stack sx={{ height: '100%' }} alignItems='center' justifyContent='center'>
      <div>
        <Typography variant='h3' sx={{ mb: '24px', textAlign: 'center' }}>
          What do you want to call your control?
        </Typography>
        <OutlinedInput
          inputProps={{ 'data-testid': 'create-control-name-input' }}
          autoFocus
          sx={{
            width: '655px',
            padding: '8px',
          }}
          placeholder='Use a descriptive name, such as "Production with PII"'
          onChange={(e) =>
            props.setWizardState({ ...props.wizardState, name: e.target.value })
          }
          onKeyDown={(e) => {
            if (e.key === 'Enter' && validateName(props.wizardState.name)) {
              next();
            }
          }}
          value={props.wizardState.name}
          endAdornment={
            <Button
              data-testid='create-control-name-continue-btn'
              sx={{ width: '200px' }}
              variant='contained'
              disabled={!validateName(props.wizardState.name)}
              onClick={next}
            >
              Continue
              <i className='material-symbols-arrow-right-alt ml-[8px]' />
            </Button>
          }
        />
      </div>
    </Stack>
  );
};
